import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				Меню | Меню Aniliun Pub
			</title>
			<meta name={"description"} content={"Насолоджуйтесь грою, шматочок за шматочком"} />
			<meta property={"og:title"} content={"Меню | Меню Aniliun Pub"} />
			<meta property={"og:description"} content={"Насолоджуйтесь грою, шматочок за шматочком"} />
			<meta property={"og:image"} content={"https://aniliunbast.com/img/banner.jpg"} />
			<link rel={"shortcut icon"} href={"https://aniliunbast.com/img/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://aniliunbast.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://aniliunbast.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://aniliunbast.com/img/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://aniliunbast.com/img/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://aniliunbast.com/img/icon.png"} />
			<meta name={"msapplication-TileColor"} content={"https://aniliunbast.com/img/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="90px 0 100px 0" quarkly-title="Price-17">
			<Text margin="0px 0px 20px 0px" font="normal 400 20px/1.5 --fontFamily-sans" color="#808890">
				МЕНЮ
			</Text>
			<Text margin="0px 40% 20px 0px" font="normal 900 48px/1.2 --fontFamily-serifGaramond" lg-margin="0px 0 20px 0px">
				Насолоджуйтесь грою, шматочок за шматочком
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://aniliunbast.com/img/m1.jpg"
						display="block"
						margin="0px 0px 25px 0px"
						width="100%"
						height="400px"
						object-fit="cover"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-serifGaramond">
						Апетитні слайдери
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://aniliunbast.com/img/m2.jpg"
						display="block"
						margin="0px 0px 25px 0px"
						width="100%"
						height="400px"
						object-fit="cover"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-serifGaramond">
						Крафтове Пиво
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://aniliunbast.com/img/m3.jpg"
						display="block"
						margin="0px 0px 25px 0px"
						width="100%"
						height="400px"
						object-fit="cover"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-serifGaramond">
						Fish 'n' Chips
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 0 0" quarkly-title="Contacts-6">
			<Override slot="SectionContent" flex-direction="column" min-width="100%" width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
				margin="0px 0px 50px 0px"
				lg-align-items="center"
			>
				<Image
					src="https://aniliunbast.com/img/m4.jpg"
					display="block"
					width="50%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					sm-height="280px"
					margin="0px 30px 0px 30px"
					lg-margin="0px 30px 30px 30px"
					lg-height="auto"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-margin="0px 0px 50px 0px"
					align-items="flex-start"
					padding="0px 50px 0px 50px"
					sm-padding="0px 15px 0px 15px"
					sm-margin="0px 0px 0 0px"
				>
					<Text
						margin="0px 10px 25px 0px"
						color="--dark"
						font="normal 500 28px/1.2 --fontFamily-sansHelvetica"
						padding="0px 10px 0px 0px"
						border-color="#c9d0d7"
						sm-border-width={0}
					>
						Актуальне меню можна отримати за телефонм нижче, у менеджера!
					</Text>
					<Text margin="0px 0px 20px 0px" font="normal 600 38px/1.2 --fontFamily-sansHelvetica" color="--dark" sm-font="normal 600 28px/1.2 --fontFamily-sansHelvetica">
						Забронювати столик:{" "}
						<br />
						+38 099 427 44 26
					</Text>
					<Text margin="0px 0px 32px 0px" font="--lead" color="#8b9197">
						Ми подбаємо про те, щоб ваша їжа в день гри була такою ж захоплюючою, як і сам матч.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-align-items="flex-start"
					>
						<Button
							margin="0px 15px 0px 0px"
							padding="14px 28px 14px 28px"
							background="#d83124"
							border-radius="50px"
							font="normal 400 16px/1.5 --fontFamily-sans"
							sm-margin="0px 15px 15px 0px"
							text-transform="uppercase"
							type="link"
							text-decoration-line="initial"
							href="/contact-us"
						>
							Знайти нас
						</Button>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});